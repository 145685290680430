<template>
  <form method="post" action="/store/cart" ref="formRef" novalidate>
    <csrf-input />
    <input type="hidden" name="itemType" :value="itemType" />
    <input type="hidden" name="addToCart" :value="itemId" />
    <input
      v-if="metaItemFromType"
      type="hidden"
      name="metaItemFromType"
      :value="metaItemFromType"
    />
    <input v-if="metaItemFromId" type="hidden" name="metaItemFromId" :value="metaItemFromId" />
    <icon-button
      type="submit"
      icon="shopping_bag"
      :icons-outlined="true"
      :reduce="false"
      :classes="buttonClasses"
      :label="buttonLabel"
      aria-live="polite"
      @click-action="handleSubmit"
    />
  </form>
</template>

<script>
import { defineComponent } from 'vue'
import CsrfInput from '@/components/forms/csrf-input.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import { trackEvent } from '@/utils/analytics'

export default defineComponent({
  name: 'AddToCartButton',
  components: { CsrfInput, IconButton },
  props: {
    // item being purchased
    itemType: {
      default: 'plan',
      type: String,
      validator: (val) => ['plan', 'product'].includes(val),
    },
    itemId: {
      type: Number,
      required: true,
    },

    // for passes, the related item that was purchased
    // with the pass (EX: some plus passes will have a plan + plan id as meta item)
    metaItemFromType: {
      default: '',
      type: String,
      validator: (val) => ['plan', 'workout', 'wellness_video', 'article', ''].includes(val),
    },
    metaItemFromId: {
      type: Number,
      default: 0,
    },
    buttonClasses: {
      type: String,
      default: '-ico-txt -no-hover -p-01',
    },
    buttonLabel: {
      type: String,
      default: 'Add To Bag',
    },

    // used for GA add to cart event tracking
    trackEvent: {
      type: Boolean,
      default: false,
    },
    eventCategory: {
      type: String,
      default: '',
    },
    eventAction: {
      type: String,
      default: '',
    },
    eventLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    async handleSubmit() {
      if (this.processing) {
        return
      }
      // disable submit
      this.processing = true

      try {
        // wrapper for GA event hitCallback so only invoked once
        let eventSubmitting = false
        const submitForm = () => {
          if (eventSubmitting) {
            return
          }

          eventSubmitting = true
          this.$refs.formRef.submit()
        }

        // ga event tracking
        if (this.trackEvent) {
          trackEvent({
            category: this.eventCategory,
            action: this.eventAction,
            label: this.eventLabel,
            transport: 'beacon',
            callback: submitForm,
          })

          // if hitCallback is not called for whatever reason, redirect w/o waiting
          setTimeout(submitForm, 1000)
        } else {
          // no tracking
          this.$nextTick(submitForm)
        }
      } catch (e) {
        // if error thrown, enable form
        this.processing = false
      }
    },
  },
})
</script>
