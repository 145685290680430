import type { CommentType } from '@/types/comment-types'

export const COMMENT_VERSION = 2

/**
 * Helper method for generate a comment link based on commentable type / id.
 *
 * @param comment
 * @returns {string}
 */
export const commentLink = (comment: CommentType): string => {
  if (comment.commentableType && comment.commentable) {
    // only link to the parent thread, it will load two pages of child comments
    const parentId = comment.parentId || comment.id

    if (comment.commentableType === 'discussion') {
      /* discussion comment link */
      return `/community/discussion/${comment.commentable.id}/${comment.commentable.seoUrl}/c/${parentId}#comments`
    } else if (comment.commentableType === 'wellness_video' && comment.commentable) {
      /* wellness-video comment link */
      return `/wellness-videos/${comment.commentable.seoUrl}/c/${parentId}#comments`
    } else if (comment.commentableType === 'plan' && comment.commentable) {
      /* plan comment link */
      return `/plans/${comment.commentable.seoUrl}/c/${parentId}#comments`
    } else if (comment.commentableType === 'article' && comment.commentable) {
      /* article comment link */
      return `/articles/${comment.commentable.seoUrl}/c/${parentId}#comments`
    } else if (comment.commentableType === 'workout' && comment.commentable) {
      /* video comment link */
      return `/videos/${comment.commentable.seoUrl}/c/${parentId}#comments`
    }
  }

  // catch all
  return ''
}

export const buildComment = (commentParam: CommentType): CommentType => {
  let { children = [] } = commentParam
  const {
    comment = '',
    imageUrl = '',
    imageDataString = '',
    id = 0,
    isCompleteBadge = false,
    isFlagged = false,
    liked = null,
    isEdited = false,
    likeCount = 0,
    likeEmoteStats = {},
    timeAgo = 'Now',
    user, // should be set
    imageError = false,
    parentId = 0,
  } = commentParam

  if (children.length) {
    children = children.map(buildComment)
  }

  return {
    children,
    comment,
    imageUrl,
    imageDataString,
    id,
    isCompleteBadge,
    isFlagged,
    liked,
    isEdited,
    likeCount,
    likeEmoteStats,
    timeAgo,
    user,
    userAdded: [],
    imageError,
    parentId,
  }
}
