<template>
  <div
    role="button"
    tabindex="0"
    class="badge-group"
    :aria-label="badgeText"
    @click="toggleBadge"
    @keypress.enter="toggleBadge"
  >
    <div aria-hidden="true" :class="badgeClass">
      <i class="material-symbols-outlined">{{ icon }}</i>
    </div>
    <outside-click v-if="badgeOpen" @outside-click-action="toggleBadge">
      <div class="badge-info -open">
        <div class="badge-info-close" aria-hidden="true">
          <i class="material-symbols-outlined">close</i>
        </div>
        <div class="badge-info-text">
          {{ badgeText }}
        </div>
      </div>
    </outside-click>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OutsideClick from '@/components/tool-tips/outside-click.vue'

export default defineComponent({
  name: 'CommentBadge',
  components: { OutsideClick },
  props: {
    icon: {
      type: String,
      required: true,
    },
    badgeClass: {
      type: String,
      default: 'badge verified',
    },
    badgeText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      badgeOpen: false,
    }
  },

  methods: {
    toggleBadge() {
      this.badgeOpen = !this.badgeOpen
    },
  },
})
</script>
